import React, { useContext } from "react";
import NewsContext from "../Context/NewsContext";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  IconButton,
  CardActions,
} from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import parse from "html-react-parser";

const Story = ({ story }) => {
  const stateData = useContext(NewsContext);
  let date1 = new Date(Date.now());
  let date2 = new Date(story.time * 1000);
  let hours = Math.abs(date1.getHours() - date2.getHours());
  let sub = "";
  if (hours !== 0) {
    sub = `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else {
    sub = "Just now";
  }

  console.log(story);

  return (
    <Card elevation={0}>
      <CardHeader
        avatar={<Avatar>{story.by.split("")[0].toUpperCase()}</Avatar>}
        title={story.by}
        subheader={sub}
      />
      <CardContent>
        <Typography variant="body1" color="textPrimary" component="p">
          {parse(story.title)}
        </Typography>
        <br />
        <Typography>
          <a href={story.url} target="_blank" rel="noreferrer">
            Read the full article
          </a>
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton
          aria-label="expand"
          onClick={() => stateData.storyClicked(story)}
        >
          <AspectRatioIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default Story;
