import React, { useContext } from "react";
import NewsContext from "../Context/NewsContext";
import Story from "./Story";
import { Toolbar } from "@mui/material";
import Masonry from "react-masonry-css";
import Details from "./Details";
import Loading from "./Loading";

const Feed = () => {
  let stateData = useContext(NewsContext);

  const breakpoints = {
    default: 2,
    700: 1,
  };

  return stateData.loading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Loading />
    </div>
  ) : (
    <div>
      <Toolbar />
      <br />
      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {stateData.topNews.map((item) => {
          return (
            <div style={{ borderRadius: "25px" }} key={item.id}>
              <Story story={item} />
            </div>
          );
        })}
      </Masonry>
      <Details />
    </div>
  );
};

export default Feed;
