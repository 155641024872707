import {
  SET_TOP_NEWS,
  SET_NEWS_ITEM,
  SET_OPEN,
  SET_COMMENTS,
  SET_LOADING,
} from "./Types";

let reducer = (state, action) => {
  let { type, payload } = action;
  switch (type) {
    case SET_TOP_NEWS:
      return {
        ...state,
        topNews: payload,
      };
    case SET_NEWS_ITEM:
      return {
        ...state,
        newsItem: payload,
      };
    case SET_OPEN:
      return {
        ...state,
        open: payload,
      };
    case SET_COMMENTS:
      return {
        ...state,
        comments: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default reducer;
