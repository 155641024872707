import React, { useEffect, useContext } from "react";
import NewsContext from "./Context/NewsContext";
import NewsState from "./Context/NewsState";
import Navbar from "./Components/Navbar";
import Feed from "./Components/Feed";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline, Container } from "@mui/material";

const App = () => {
  let stateData = useContext(NewsContext);

  useEffect(() => {
    stateData.setTopNews();
  }, []);

  let theme = createTheme({
    typography: {
      fontFamily: "Manrope",
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ backgroundColor: "#EBEBEB" }}>
        <Container>
          <Navbar />
          <Feed />
        </Container>
      </div>
    </ThemeProvider>
  );
};

const AppContext = () => {
  return (
    <NewsState>
      <App />
    </NewsState>
  );
};

export default AppContext;
