import React from "react";
import { AppBar, Typography, Toolbar } from "@mui/material";

const Navbar = () => {
  return (
    <AppBar elevation={0} sx={{ backgroundColor: "black" }}>
      <Toolbar>
        <Typography variant="h4" sx={{ fontFamily: "Monofett" }}>
          Hacker News
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
