import React, { useContext } from "react";
import NewsContext from "../Context/NewsContext";
import {
  Dialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Loading from "./Loading";
import parse from "html-react-parser";

const Details = () => {
  let stateData = useContext(NewsContext);
  let date1 = new Date(Date.now());
  let date2 = new Date(stateData.newsItem.time * 1000);
  let hours = Math.abs(date1.getHours() - date2.getHours());
  let sub = "";
  if (hours !== 0) {
    sub = `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else {
    sub = "Just now";
  }

  return (
    <Dialog fullScreen open={stateData.open} onClose={stateData.handleClose}>
      <DialogContent sx={{ backgroundColor: "#EBEBEB" }}>
        <Card elevation={0}>
          <CardHeader
            avatar={
              <Avatar>
                {stateData.newsItem.by.split("")[0].toUpperCase()}
              </Avatar>
            }
            title={stateData.newsItem.by}
            subheader={sub}
          />
          <CardContent>
            <Typography variant="body1" color="textPrimary" component="p">
              {parse(stateData.newsItem.title)}
            </Typography>
            <br />
            <Typography>
              <a href={stateData.newsItem.url} target="_blank" rel="noreferrer">
                Read the full article
              </a>
            </Typography>
          </CardContent>
        </Card>
        {stateData.loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <>
            <br />
            {!stateData.comments ? (
              <Typography variant="h4" gutterBottom>
                No Comments
              </Typography>
            ) : (
              <List sx={{ width: "80%" }}>
                {stateData.comments.map((item) => {
                  return (
                    <ListItem key={item.id} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>{item.by.split("")[0].toUpperCase()}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.by}
                        secondary={parse(item.text)}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
            <br />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={stateData.handleClose}
            >
              <IconButton aria-label="close dialog">
                <CancelIcon />
              </IconButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Details;
