import { createContext } from "react";

let NewsContext = createContext({
  topNews: [],
  newsItem: {
    by: "tempodox",
    descendants: 125,
    id: 27956313,
    kids: [
      27958070, 27958133, 27957558, 27957035, 27956897, 27959187, 27957375,
      27964384, 27957073, 27957166, 27963768, 27957076, 27956949, 27964295,
      27957454, 27958257, 27957196, 27957656, 27958519,
    ],
    score: 467,
    time: 1627280790,
    title: "Understanding Rust futures by going way too deep",
    type: "story",
    url: "https://fasterthanli.me/articles/understanding-rust-futures-by-going-way-too-deep",
  },
  open: false,
  comments: [],
  loading: false,
});

export default NewsContext;
