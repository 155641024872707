import { useReducer } from "react";
import Context from "./NewsContext";
import reducer from "./NewsReducer";
import axios from "axios";
import {
  SET_TOP_NEWS,
  SET_NEWS_ITEM,
  SET_OPEN,
  SET_COMMENTS,
  SET_LOADING,
} from "./Types";

const NewsState = (props) => {
  let initialState = {
    topNews: [],
    newsItem: {
      by: "tempodox",
      descendants: 125,
      id: 27956313,
      kids: [
        27958070, 27958133, 27957558, 27957035, 27956897, 27959187, 27957375,
        27964384, 27957073, 27957166, 27963768, 27957076, 27956949, 27964295,
        27957454, 27958257, 27957196, 27957656, 27958519,
      ],
      score: 467,
      time: 1627280790,
      title: "Understanding Rust futures by going way too deep",
      type: "story",
      url: "https://fasterthanli.me/articles/understanding-rust-futures-by-going-way-too-deep",
    },
    open: false,
    comments: [],
    loading: false,
  };

  let [state, dispatch] = useReducer(reducer, initialState);

  const fetchTopNews = async () => {
    try {
      let res1 = await axios.get(
        "https://hacker-news.firebaseio.com/v0/topstories.json"
      );
      let populatedNews = await Promise.all(
        res1.data.slice(0, 100).map((id) => {
          return axios.get(
            `https://hacker-news.firebaseio.com/v0/item/${id}.json`
          );
        })
      );
      populatedNews = populatedNews.map((item) => {
        return item.data;
      });
      return populatedNews;
    } catch (error) {
      console.error(error);
    }
  };

  const setTopNews = async () => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      let storage = JSON.parse(localStorage.getItem("news"));
      if (!storage || Date.now() - storage.time > 7200000) {
        let news = await fetchTopNews();
        dispatch({ type: SET_LOADING, payload: false });
        dispatch({ type: SET_TOP_NEWS, payload: news });
        localStorage.setItem(
          "news",
          JSON.stringify({
            time: Date.now(),
            news: news,
          })
        );
      } else {
        let news = storage.news;
        dispatch({ type: SET_LOADING, payload: false });
        dispatch({ type: SET_TOP_NEWS, payload: news });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.error(error);
    }
  };

  const storyClicked = async (story) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_OPEN, payload: true });
      dispatch({ type: SET_NEWS_ITEM, payload: story });
      let l = story.kids.length > 5 ? 5 : story.kids.length;
      let com = [];
      for (let i = 0; i < l; i++) {
        let res = await axios.get(
          `https://hacker-news.firebaseio.com/v0/item/${story.kids[i]}.json`
        );
        com.push(res.data);
      }
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_COMMENTS, payload: com });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.error(error);
    }
  };

  const handleClose = () => {
    dispatch({ type: SET_LOADING, payload: false });
    dispatch({ type: SET_OPEN, payload: false });
    dispatch({ type: SET_COMMENTS, payload: [] });
  };

  return (
    <Context.Provider
      value={{ ...state, setTopNews, storyClicked, handleClose }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default NewsState;
